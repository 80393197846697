import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@mui/material/styles';
import './App.css';
import Routes from './routes';
import './i18n';
import { API_URL } from './components/constants/index';
import { HospitalAction, languageAction } from './reducers';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from './components/constants/AxiosInstance';
const theme = createTheme({
  palette: {
    primary: {
      main: '#004ea2',
      light: '#40a8c4',
    },
    secondary: {
      main: '#080059',
    },
    error: {
      main: '#eb5e2d',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    //fontFamily: ['Noto Sans', 'sans-serif'].join(','),
  },
});
const App = () => {
  const language = useSelector((state) => state.languageSlice.selectedLanguage);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const resp = await axiosInstance.get(`${API_URL}/hospital/in/${language}`);
      // const allRes = await axiosInstance.get(`${API_URL}/hospital/`);
      const data = await resp?.data;
      const value = await data?.value;
      dispatch(HospitalAction.getHospitalList(value));
      // dispatch(HospitalAction.getHospitalAllList(allRes.data.value));

      // 브라우저 언어 o
      let browserLang = navigator.language;
      let initLang = browserLang ? browserLang.substring(0, 2) : 'en';

      // 한국에서 접속시 언어 영어로..
      if (browserLang.substring(0, 2) === 'ko') initLang = 'en';

      console.log(window.location.href);
      if(window.location.href.indexOf('vn.koreamedis.com') !== -1) initLang = 'vi';
      if(window.location.href.indexOf('cn.koreamedis.com') !== -1) initLang = 'zh';
      if(window.location.href.indexOf('mn.koreamedis.com') !== -1) initLang = 'mn';
      if(window.location.href.indexOf('ru.koreamedis.com') !== -1) initLang = 'ru';
      if(window.location.href.indexOf('en.koreamedis.com') !== -1) initLang = 'en';
      if(window.location.href.indexOf('kr.koreamedis.com') !== -1) initLang = 'ko';
      console.log('initLang', initLang);

      dispatch(languageAction.setLanguage(initLang));
    })();
  }, []);

  return (
    <div style={{ backgroundColor: '#ffffff' }}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
};

export default App;
