import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './index.css';

const TreatmentReview = React.lazy(() => import('./components/TreatmentReview/TreatmentReview'));
const TreatmentReviewDetail = React.lazy(() => import('./components/TreatmentReview/TreatmentReviewDetail'));

const ChangePW = React.lazy(() => import('./pages/MyPage/ChangePW'));
const MyConsultationDetail = React.lazy(() => import('./pages/MyPage/MyConsultationDetail'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const PrivacyTerms = React.lazy(() => import('./pages/PrivacyTerms'));

const SearchPage = React.lazy(() => import('./pages/Search/SearchPage'));

const Servicecenter = React.lazy(() => import('./pages/Servicecenter'));
const About = React.lazy(() => import('./pages/About'));
const MedicalCaseDetail = React.lazy(() => import('./components/MedicalCase/MedicalCaseDetail'));
const ScrollToTop = React.lazy(() => import('./components/ScrollTop'));
const FAQ = React.lazy(() => import('./pages/FAQ'));
const VideoConsultation = React.lazy(() => import('./pages/VideoConsultation'));

const MedicalCase = React.lazy(() => import('./pages/MedicalCase'));
const Layout = React.lazy(() => import('./Layout/Layout'));
const MainPage = React.lazy(() => import('./pages/MainPage'));
const SignIn = React.lazy(() => import('./pages/loginPage'));
const SignUp = React.lazy(() => import('./pages/joinPage'));
const Forgot = React.lazy(() => import('./pages/Forgot'));

const Hospital = React.lazy(() => import('./pages/Hospital'));
const VideoConsulHospital = React.lazy(() => import('./pages/VideoConsultHospital'));
const MedicalConsultation = React.lazy(() => import('./pages/MedicalConsultation'));
const HospitalDetail = React.lazy(() => import('./components/hospital/HospitalDetail'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));
const Goods = React.lazy(() => import('./pages/Goods'));
const Doctors = React.lazy(() => import('./pages/Doctors'));
const DoctorDetail = React.lazy(() => import('./components/Doctors/DoctorDetail'));
const Disease = React.lazy(() => import('./pages/Disease/Disease'));

const MedicalVisa = React.lazy(() => import('./pages/NonMedical/MedicalVisa'));
const AirportTransfer = React.lazy(() => import('./pages/NonMedical/AirportTransfer'));
const HotelRes = React.lazy(() => import('./pages/NonMedical/HotelReservation'));

const News = React.lazy(() => import('./pages/News'));
const NewsDetail = React.lazy(() => import('./pages/NewsDetail'));

const MyInfo = React.lazy(() => import('./pages/MyPage/MyInfo'));
const MyChart = React.lazy(() => import('./pages/MyPage/MyChart'));
const MyChartList = React.lazy(() => import('./pages/MyPage/MyChartList'));

const MyConsultation = React.lazy(() => import('./pages/MyPage/MyConsultationList'));
const MyReservation = React.lazy(() => import('./pages/MyPage/MyReservation'));
const NewReservation = React.lazy(() => import('./pages/MyPage/NewReservation'));

const InterestingHospital = React.lazy(() => import('./pages/MyPage/InterestingHospital'));
const InterestingProducts = React.lazy(() => import('./pages/MyPage/InterestingProducts'));
const MyReviewList = React.lazy(() => import('./pages/MyPage/MyReviewList'));
const ReviewForm = React.lazy(() => import('./pages/MyPage/ReviewForm'));

const TourPromotion = React.lazy(() => import('./pages/NonMedical/TourismPromotion'));
const GoodsDetail = React.lazy(() => import('./pages/GoodsDetail'));
const Paypal = React.lazy(() => import('./components/goodsDetail/Paypal'));
const ConsultCaseDetail = React.lazy(() => import('./pages/consultCaseDetail/CaseDetail'));
const MessagePage = React.lazy(() => import('./pages/MyPage/message/MessagePage'));
const MessageDetailPage = React.lazy(() => import('./pages/MyPage/message/MessageDetailPage'));
const MessageRegPage = React.lazy(() => import('./pages/MyPage/message/MessageRegPage'));

const Routes = () => (
  <Router>
    <Suspense
      fallback={
        <div className="lazy-loading-bg">
          <span className="lazy-loading"></span>
        </div>
      }
    >
      <Layout>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <MainPage />
            </Route>
            <Route exact path="/search">
              <SearchPage />
            </Route>
            <Route exact path="/mainpage">
              <MainPage />
            </Route>
            <Route exact path="/forgot">
              <Forgot />
            </Route>
            <Route exact path="/sign-in">
              <SignIn />
            </Route>
            <Route exact path="/sign-up">
              <SignUp />
            </Route>
            <Route exact path="/info/disease">
              <Disease />
            </Route>
            <Route exact path="/info/hospital">
              <Hospital />
            </Route>
            <Route exact path="/info/goods/paypal/">
              <Paypal />
            </Route>
            <Route exact path="/info/video-consult-hos">
              <VideoConsulHospital />
            </Route>
            <Route exact path="/info/hospital/:hospitalId">
              <HospitalDetail />
            </Route>
            <Route exact path="/consult/med-consultation">
              <MedicalConsultation />
            </Route>
            <Route exact path="/info/goods">
              <Goods />
            </Route>
            <Route exact path="/info/goods/:goodsId">
              <GoodsDetail />
            </Route>
            <Route exact path="/info/treatment-review">
              <TreatmentReview />
            </Route>
            <Route exact path="/info/treatment-review-detail/:reviewId">
              <TreatmentReviewDetail />
            </Route>
            <Route exact path="/info/doctors">
              <Doctors />
            </Route>
            <Route exact path="/info/doctors/:doctorId">
              <DoctorDetail />
            </Route>
            <Route exact path="/consult/medicalcase">
              <MedicalCase />
            </Route>
            <Route exact path="/consult/medicalcase/:caseId">
              <MedicalCaseDetail />
            </Route>
            <Route exact path="/consult/faq">
              <FAQ />
            </Route>
            <Route exact path="/med-case-detail/:caseId">
              <ConsultCaseDetail />
            </Route>

            <Route exact path="/consult/video">
              <VideoConsultation />
            </Route>
            <Route exact path="/nonmed/medical-visa">
              <MedicalVisa />
            </Route>
            <Route exact path="/nonmed/airport-transfer">
              <AirportTransfer />
            </Route>
            <Route exact path="/hotel-reservation">
              <HotelRes />
            </Route>
            <Route exact path="/tour-promotion">
              <TourPromotion />
            </Route>
            <Route exact path="/news/:catId">
              <News />
            </Route>
            <Route exact path="/newsdetail/:newsId">
              <NewsDetail />
            </Route>
            <Route exact path="/koreamedis/about">
              <About />
            </Route>
            <Route exact path="/koreamedis/terms">
              <PrivacyTerms />
            </Route>
            <Route exact path="/koreamedis/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route exact path="/koreamedis/servicecenter">
              <Servicecenter />
            </Route>
            <Route exact path="/mypage/my-info">
              <MyInfo />
            </Route>
            <Route exact path="/mypage/my-info/change-pw">
              <ChangePW />
            </Route>
            <Route exact path="/mypage/my-chart/:chartId">
              <MyChart />
            </Route>
            <Route exact path="/mypage/my-chart">
              <MyChart />
            </Route>
            <Route exact path="/mypage/my-chart-list">
              <MyChartList />
            </Route>
            <Route exact path="/mypage/my-consultation">
              <MyConsultation />
            </Route>
            <Route exact path="/mypage/my-consultation-detail/:consultId">
              <MyConsultationDetail />
            </Route>
            <Route exact path="/mypage/my-reservation">
              <MyReservation />
            </Route>
            <Route exact path="/mypage/new-reservation">
              <NewReservation />
            </Route>
            <Route exact path="/mypage/message">
              <MessagePage />
            </Route>
            <Route exact path="/mypage/message/:msgId">
              <MessageDetailPage />
            </Route>
            <Route exact path="/mypage/new-message">
              <MessageRegPage />
            </Route>
            <Route exact path="/mypage/interesting-hospital">
              <InterestingHospital />
            </Route>
            <Route exact path="/mypage/interesting-products">
              <InterestingProducts />
            </Route>
            <Route exact path="/mypage/my-review-list">
              <MyReviewList />
            </Route>
            <Route exact path="/mypage/review-form">
              <ReviewForm />
            </Route>
            <Route exact path="/nonemed/hotel-reservation">
              <HotelRes />
            </Route>
            <Route exact path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </ScrollToTop>
      </Layout>
    </Suspense>
  </Router>
);
export default Routes;
