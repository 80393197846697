/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-northeast-2",
  "aws_cognito_identity_pool_id": "ap-northeast-2:eff4ffa5-b4ec-489c-9eae-60ae6026e337",
  "aws_cognito_region": "ap-northeast-2",
  "aws_user_pools_id": "ap-northeast-2_TyFpXYXUI",
  "aws_user_pools_web_client_id": "14l07p205ha1tcmscktrvggrlu",
  "oauth": {},
};


export default awsmobile;