import dotenv from 'dotenv';
import CryptoJS from 'crypto-js';
import { Auth } from 'aws-amplify';
import Amplify, { Storage } from 'aws-amplify';

import _ from 'lodash';

dotenv.config(Storage);
export const API_URL = 'https://api.koreamedis.co.kr';
// export const API_URL = 'http://localhost:3000';
export const BASE_IMG_URL = 'https://s3.ap-northeast-2.amazonaws.com/file.koreamedis.com/';
export const EXCHANGE_EXTERNAL_URL = 'https://open.er-api.com/v6/latest';

export const KAKAKO_KEY = '96d0a8ff8acfd4e067e1eba97ba1e71a';
export const FACEBOOK_KEY = '351960399970939';
export const GOOGLE_KEY = '397566369903-ngtlijo5ktr0ufbrc99gv31sne591n1j.apps.googleusercontent.com';
export const NAVER_KEY = '3Ty7pgGgkub34VU9WnkL';
export const NAVER_SECRET_KEY = 'aWyvwumxGD';

export const FOR_IOS_APP_REVIEW = false; //chat 숨기기는 직접 해야함.

export const NOTICE_CATEGORY = [
  { id: 'notice', name_ko: '전체공지' },
  { id: 'partner', name_ko: '파트너' },
  { id: 'hospital', name_ko: '병원' },
  { id: 'etc', name_ko: '기타' },
];

export const RELATIONSHIP = [
  { id: 'me', name_ko: '본인' },
  { id: 'spouse', name_ko: '배우자' },
  { id: 'parent', name_ko: '부모' },
  { id: 'children', name_ko: '자식' },
  { id: 'etc', name_ko: '기타' },
];

export const ARR_DAY = [
  { id: 'mon', name_ko: '월요일' },
  { id: 'tue', name_ko: '화요일' },
  { id: 'wed', name_ko: '수요일' },
  { id: 'thu', name_ko: '목요일' },
  { id: 'fri', name_ko: '금요일' },
];

export const MEMBER_TYPE = [
  { id: 'member', name_ko: '일반회원' },
  { id: 'partner', name_ko: '파트너' },
  { id: 'hospital', name_ko: '병원 담당자' },
  { id: 'manager', name_ko: '담당자' },
];

export const MEMBER_STATUS = [
  { id: 'approval', name_ko: '승인' },
  { id: 'waiting', name_ko: '승인대기' },
  { id: 'badmember', name_ko: '불량회원' },
];

export const CONSULT_STATUS = [
  { id: 'request', name_ko: '상담요청' },
  { id: 'complete', name_ko: '상담완료' },
  { id: 'cancel', name_ko: '상담취소' },
];

export const BOOKING_CATEGORY = [
  { id: 'hospital', name_ko: '병원예약' },
  { id: 'product', name_ko: '상품예약' },
  { id: 'pickup', name_ko: '픽업예약' },
];

export const BOOKING_STATUS = [
  { id: 'request', name_ko: '예약요청' },
  { id: 'complete', name_ko: '예약완료' },
  { id: 'cancel', name_ko: '예약취소' },
];

export const GENDER_TYPE = [
  { id: 'male', name_ko: '남성' },
  { id: 'female', name_ko: '여성' },
  { id: 'etc', name_ko: '기타' },
];

export const DEF_NOTICE_CATE = NOTICE_CATEGORY[0].id;
export const DEF_NEWS_CATE = 'news';
export const DEF_BOOKING_CATE = BOOKING_CATEGORY[0].id;
export const DEF_BOOKING_STATUS = BOOKING_STATUS[0].id;
export const DEF_CONSULT_STATUS = CONSULT_STATUS[0].id;
export const DEF_GENDER = GENDER_TYPE[0].id;
export const DEF_COUNTRY = 'KOR'; //alpha3
export const DEF_LANGUAGE = 'ko'; //기본값 한국어
export const DEF_DATE = new Date().toString();
export const DEF_USER_STATUS = MEMBER_STATUS[0].id;

export const getTypeName = (types, id) => {
  const type = types.find((cate) => cate.id === id);
  return type ? type[getDefName()] : '';
};

export const getSystemLanguage = () => {
  return localStorage.getItem('systemLanguage') || DEF_LANGUAGE;
};
export const getDefName = () => {
  return `name_${DEF_LANGUAGE}`;
};
export const getUser = () => {
  return Auth.currentAuthenticatedUser()
    .then((userData) => userData)
    .catch(() => {
      // console.log('Not signed in');
    });
};
export const encrypt = (str) => {
  const hash = CryptoJS.MD5(str);
  const key = CryptoJS.enc.Utf8.parse(hash); // hex로 변환
  return CryptoJS.enc.Base64.stringify(key);
};

export const decrypt = (str) => {
  const decrypt = CryptoJS.enc.Base64.parse(str);
  return decrypt.toString(CryptoJS.enc.Utf8);
};

export const encryptAES = (str) => {
  return CryptoJS.AES.encrypt(str, process.env.REACT_APP_ENC_KEY);
};

export const decryptAES = (str) => {
  const dc = CryptoJS.AES.decrypt(str, process.env.REACT_APP_ENC_KEY);
  return dc.toString(CryptoJS.enc.Utf8);
};

export const groupBy = (array, key) => {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const tokenRefresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    // // console.log(cognitoUser);
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (session) {
        const { idToken } = session;
        sessionStorage.setItem('bt_jwt', idToken.jwtToken);
        return Promise.resolve(idToken.jwtToken);
      } else {
        // console.log(err.message);
      }
    });
  } catch (e) {
    // console.log('Unable to refresh Token', e);
  }
};

export const EMAIL_REG = /^([-_\.0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
export const emailValidation = (email) => {
  if (EMAIL_REG.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const PW_REG = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

export const pwValidation = (password) => {
  if (PW_REG.test(password)) {
    return true;
  } else {
    return false;
  }
};
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

export const convertURLtoFile = async (url) => {
  const response = await fetch(url);
  const data = await response.blob();
  const ext = url.split('.').pop(); // url 구조에 맞게 수정할 것
  const filename = url.split('/').pop(); // url 구조에 맞게 수정할 것
  //const metadata = { type: `image/${ext}` };
  return new File([data], filename || '');
};
export const convertObjtoFile = async (obj) => {
  const result = await Storage.get(obj.key, {
    contentType: '*',
    customPrefix: { public: '' },
    download: true,
  });
  const ext = obj.url.split('.').pop(); // url 구조에 맞게 수정할 것
  const filename = obj.url.split('/').pop(); // url 구조에 맞게 수정할 것
  const metadata = { type: `image/${ext}` };
  return new File([result.Body], filename || '');
};
export const CATEGORY_ALL = {
  id: 'all',
  name_ko: '전체',
  name_mn: 'бүгд',
  name_zh: '全部',
  name_ru: 'Bсе',
  name_vi: 'tất cả các',
  name_en: 'All',
  sortOrder: -1,
};

export const strip = (html) => {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  // // console.log(doc.body.textContent || '');
  return doc.body.textContent || '';
};

// merge object 함수
export const mergeObj = (arr1, arr2) => {
  let result = _.cloneDeep(arr1);
  let itemIdx = result.findIndex((x) => x.id === arr2?.id);

  if (itemIdx > -1) {
    result[itemIdx] = arr2;
  } else {
    result.push(arr2);
  }

  return _.cloneDeep(result);
};
