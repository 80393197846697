import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './locales/en/translationEn.json';
import translationKor from './locales/kr/translationKr.json';
import translationCn from './locales/cn/translationCn.json';
import translationRus from './locales/rus/translationRus.json';
import translationVn from './locales/vn/translationVn.json';
import translationMn from './locales/mn/translationMn.json';

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEn,
      },
      ko: {
        translation: translationKor,
      },
      zh: {
        translation: translationCn,
      },
      ru: {
        translation: translationRus,
      },
      vi: {
        translation: translationVn,
      },
      mn: {
        translation: translationMn,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
