import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { API_URL } from './components/constants/index';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const KoreMedisApi = createApi({
  reducerPath: 'mainQueries',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  endpoints: (builder) => ({
    getHospital: builder.query({
      query: () => `/hospital`,
    }),
    getSelectedHospital: builder.query({
      query: (id) => `/hospital/${id}`,
    }),
    getHospitalType: builder.query({
      query: (id) => `/hospitalType/${id}`,
    }),
    getDoctor: builder.query({
      query: () => '/doctor',
    }),
    getSelectedDoctor: builder.query({
      query: (id) => `/doctor/${id}`,
    }),
    getFaq: builder.query({
      query: (languageId) => `/faq/in/${languageId}`,
    }),
    getFaqCat: builder.query({
      query: () => `faq/category`,
    }),
    getProduct: builder.query({
      query: () => '/product/',
    }),
    getSelectedProduct: builder.query({
      query: (id) => `/product/${id}/`,
    }),
    getDisease: builder.query({
      query: () => '/disease',
    }),
    getSubDisease: builder.query({
      query: () => '/subDisease',
    }),
    getConsultCase: builder.query({
      query: () => '/consultCase',
    }),
    getNews: builder.query({
      query: () => `/news/`,
    }),
    getRegion: builder.query({
      query: (id) => `/region/${id}`,
    }),
    getReview: builder.query({
      query: () => `/review`,
    }),
  }),
});

export const {
  useGetHospitalQuery,
  useGetDoctorQuery,
  useGetFaqQuery,
  useGetProductQuery,
  useGetDiseaseQuery,
  useGetSubDiseaseQuery,
  useGetFaqCatQuery,
  useGetConsultCaseQuery,
  useGetNewsQuery,
  useGetSelectedHospitalQuery,
  useGetSelectedDoctorQuery,
  useGetRegionQuery,
  useGetHospitalTypeQuery,
  useGetSelectedProductQuery,
  useGetReviewQuery,
} = KoreMedisApi;

const browserLang = navigator.language;
const initialState = {
  selectedLanguage: browserLang ? browserLang.substring(0, 2) : 'en',
};

const langs = ['en', 'ko', 'ru', 'zh', 'mn', 'vi'];
export const languageSlice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    getLanguage(state, action) {
      state.selectedLanguage = action.payload;
    },
    setLanguage(state, action) {
      const lang = langs.includes(action.payload) ? action.payload : 'en';

      state.selectedLanguage = lang;
    },
  },
});

const userInfo = {
  user_name: '',
  user_id: '',
  user_jwt: '',
  user_lang: '',
  user_country: '',
};

let searchInfo = {
  keyword: '',
  searchMode: false,
  searchData: '',
};

const diseaseInfo = {
  dsList: '',
  subList: '',
};
const prodSate = {
  products: [],
};
export const productSlice = createSlice({
  name: 'products',
  initialState: prodSate,
  reducers: {
    getProducts(state, action) {
      state.products = action.payload;
    },
  },
});
export const prodAction = productSlice.actions;
export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: userInfo,
  reducers: {
    getUserName(state, action) {
      state.user_name = action.payload;
    },
    getUserId(state, action) {
      state.user_id = action.payload;
    },
    getUserJWT(state, action) {
      state.user_jwt = action.payload;
    },
    getUserLanguageId(state, action) {
      state.user_languageId = action.payload;
    },
    getUserCountryId(state, action) {
      state.user_countryId = action.payload;
    },
    deleteUserInfo(state, action) {
      state.user_name = '';
      state.user_id = '';
      state.user_jwt = '';
      state.user_languageId = '';
      state.user_countryId = '';
    },
  },
});
const hosInitialState = {
  hospitalList: '',
  // hospitalAllList: '',
  hospitalTypeList: '',
};
export const hospitalSlice = createSlice({
  name: 'hospitalList',
  initialState: hosInitialState,
  reducers: {
    // getHospitalAllList(state, action) {
    //   state.hospitalAllList = action.payload;
    // },
    getHospitalList(state, action) {
      state.hospitalList = action.payload;
    },
    getHospitalTypeList(state, action) {
      state.hospitalTypeList = action.payload;
    },
  },
});
export const HospitalAction = hospitalSlice.actions;
export const searchSlice = createSlice({
  name: 'searchInfo',
  initialState: searchInfo,
  reducers: {
    setKeyword(state, action) {
      state.keyword = action.payload;
    },
    setSearchMode(state, action) {
      state.searchMode = action.payload;
    },
    setSearchData(state, action) {
      state.searchData = action.payload;
    },
  },
});
export const diseaseSlice = createSlice({
  name: 'diseaseInfo',
  initialState: diseaseInfo,
  reducers: {
    setDsList(state, action) {
      state.dsList = action.payload;
    },
    setSubDsList(state, action) {
      state.subList = action.payload;
    },
  },
});

export const doctorSlice = createSlice({
  name: 'doctor',
  initialState: { doctorList: null },
  reducers: {
    getDoctor(state, action) {
      state.doctorList = action.payload;
    },
  },
});
export const userInfoAction = userInfoSlice.actions;
export const languageAction = languageSlice.actions;
export const hospitalListAction = hospitalSlice.actions;
export const searchAction = searchSlice.actions;
export const diseaseAction = diseaseSlice.actions;
export const doctorAction = doctorSlice.actions;
const rootReducer = combineReducers({
  [KoreMedisApi.reducerPath]: KoreMedisApi.reducer,
  languageSlice: languageSlice.reducer,
  userInfoSlice: userInfoSlice.reducer,
  hospitalInfoSlice: hospitalSlice.reducer,
  searchSlice: searchSlice.reducer,
  diseaseSlice: diseaseSlice.reducer,
  productSlice: productSlice.reducer,
  KoreMedisApi: KoreMedisApi.reducer,
});
export default rootReducer;
